<template>
  <article class="checkByTeacher" v-loading="loading">
    <section class="content">
      <header class="header">
        <h1>我的布置</h1>
        <div class="row">
          <div class="left">
            <div class="search-input">
              <el-autocomplete
                  class="input"
                  v-model="searchText"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入学校名称搜索"
                  :trigger-on-focus="false"
                  @select="handleSelect"></el-autocomplete>
              <div class="icon"></div>
            </div>
            <DatePicker v-model="timeRang" :time-interval="180" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></DatePicker>
            <el-button class="search-btn" type="primary" plain @click="findBagListByCreator">查询</el-button>
            <!-- TODO 记得改样式-->
            <el-select class="select-status" v-model="status" @change="statusChange">
              <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="right">
            <div v-if="selection.length" class="right-btn delete" @click="batchDelete">删除所选</div>
            <div class="right-btn export" @click="batchExport">批量导出</div>
          </div>
        </div>
      </header>

      <div class="line"></div>

      <BagList
          :list="list"
          :show-check="true"
          @exportExcel="exportExcel"
          @delWork="delWork"
          @bagWorkList="bagWorkList"
          @setWork="setWork"
          @selectionChange="selectionChange"
      ></BagList>
      <div class="pagination-container">
        <Pagination :page-size="pageSize" :total="total" :page="page" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </div>
    </section>
    <EditWorkSet ref="EditWorkSet" @refreshPage="refreshPage"></EditWorkSet>
  </article>
</template>

<script>
import BagList from './components/BagList.vue'
import EditWorkSet  from '@/components/EditWorkSet'
import DatePicker  from '@/components/common/DatePicker'
import Pagination from '@/components/common/Pagination.vue'
import { batchDeleteHomeworkBag, exportBagByCreator, findBagPageByCreator } from '@/api/homework.js'
import dayjs from 'dayjs'
export default {
  name: 'userWork',
  components: {
    BagList,
    EditWorkSet,
    DatePicker,
    Pagination
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  data() {
    return {
      loading: false,
      searchText: '',
      searchId: '',
      schoolId: '',
      schoolList: [],
      statusList: [
        { 'id': '', 'name': '全部状态' },
        { 'id': '0', 'name': '未开始' },
        { 'id': '1', 'name': '进行中' },
        { 'id': '2', 'name': '已结束' }
      ],
      status: '',
      list: [],
      selection: [],
      page: 1,
      pageSize: 20,
      total: 1,
      timeRang: [],
      startTime: '',
      endTime: ''
    }
  },
  watch: {
    searchText(val) {
      const {schoolList} = this
      let searchId = ''
      for (let i = 0, len = schoolList.length; i < len; i += 1) {
        if (val === schoolList[i].schoolName) {
          searchId = schoolList[i].schoolId
          break
        }
      }
      this.searchId = searchId
    },
    timeRang: {
      deep:true,
      handler: function (val) {
        this.startTime = val[0] ? dayjs(val[0]).format('YYYY-MM-DD 00:00:00') : ''
        this.endTime = val[1] ? dayjs(val[1]).format('YYYY-MM-DD 23:59:59') : ''
      }
    }
  },
  created() {
    this.startTime = dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00:00')
    this.endTime = dayjs().format('YYYY-MM-DD 23:59:59')
    this.timeRang = [dayjs(this.startTime).format('YYYY-MM-DD'), dayjs(this.endTime).format('YYYY-MM-DD')]
    this.findBagListByCreator()
    this.getSchoolList()
  },
  activated() {
    if (this.common.globalData.userWorkRefresh) {
      this.common.globalData.userWorkRefresh = false
      this.findBagListByCreator()
    }
  },
  methods: {
    getSchoolList() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getSchoolList
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {data} = response
          for (let i = 0, len = data.length; i < len; i += 1) {
            data[i].value = data[i].schoolName
          }
          this.schoolList = data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    querySearch(queryString, cb) {
      const {schoolList} = this
      const list = schoolList.filter(this.schoolFilter(queryString))
      cb(list)
    },
    handleSelect(item) {
      this.schoolId = item.schoolId
    },
    schoolFilter(name) {
      return (school) => {
        return (school.schoolName.indexOf(name) !== -1)
      }
    },
    handleSizeChange(val) {
      this.page = val
      this.findBagListByCreator()
    },
    handleCurrentChange(val) {
      this.page = val
      this.findBagListByCreator()
    },
    chooseTime(start, end) {
      this.startTime = start
      this.endTime = end
    },
    bagWorkList(index) {
      const {list} = this
      const {id, name, taskType} = list[index]
      if (taskType === 3) {
        this.$confirm('该内容Web版未适配，请前往APP查看', '提示', {
          type: 'warning'
        }).then(() => {})
        return
      }
      this.$router.push({
        name: 'bagWorkList',
        query: {
          id,
          name
        }
      })
    },
    exportExcel(itemId) {
      const that = this
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      this.$confirm('确认导出成绩吗？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const convertScore = checked ? 1 : 0
        checked = false
        // const url = `${that.$urls.exportBagScore}?bagId=${itemId}`
        // // window.open(url)
        // const downloadElement = document.createElement('a')
        // downloadElement.href = url
        // document.body.appendChild(downloadElement)
        // downloadElement.click()
        that.$axios({
          method: 'post',
          url: that.$urls.exportBagScope,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            bagId: itemId,
            convertScore
          }
        }).then((response) => {
          if (response.state === '11') {
            that.$message.success('下载任务已提交，请前往下载中心查看进度')
          }
        }).catch(() => {
        })
      }).catch((err) => {
        checked = false
        throw err
      })
    },
    // 批量导出
    batchExport() {
      const that = this
      const { startTime, endTime, total, userInfo, status, schoolId } = this
      if (total > 200) {
        this.$message({
          type: 'warning',
          message: '批量导出作业数量不能超过200个'
        })
        return
      }
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      this.$confirm('确认批量导出作业包？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const convertScore = checked ? 1 : 0
        checked = false
        const dataParam = {
          teacherId: userInfo.teacherId,
          startTime,
          endTime,
          convertScore,
          status,
          schoolId
        }
        that.loading = true
        exportBagByCreator(dataParam).then(response => {
          that.loading = false
          if (response.state === '11') {
            that.$message.success('下载任务已提交，请前往下载中心查看进度')
          }
        }).catch(() => {
          that.loading = false
        })
      }).catch((err) => {
        throw err
      })
    },
    setDate(index, date) {
      const {list} = this
      const {id} = list[index]
      date = this.common.binarySystem(date)
      date = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(date))
      this.$axios({
        method: 'post',
        url: this.$urls.homeworkUpdateItem,
        data: {
          itemId: id,
          dateEnd: date
        }
      }).then((response) => {
        if (response.state === '11') {
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          const time = this.common.formatDate('MM-dd hh:mm', new Date(date))
          this.$set(this.list[index], 'showDateEnd', time)
        }
      })
    },
    delWork(index) {
      const that = this
      this.$confirm('删除后学生将无法收到该作业？', '删除作业', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.confirmDel(index)
      }).catch((err) => {
        throw err
      })
    },
    confirmDel(index) {
      const {list} = this
      const {id} = list[index]
      let url
      const dataParam = {}
      url = this.$urls.deleteHomeBag
      dataParam.bagId = id
      this.$axios({
        method: 'post',
        url,
        data: dataParam
      }).then((response) => {
        if (response.state === '11') {
          this.list.splice(index, 1)
        }
      })
    },
    // 批量删除
    batchDelete() {
      const that = this
      const { userInfo, selection } = this
      this.$confirm('删除后学生将无法收到所选作业？', '删除作业', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          homeworkBagIds: selection.map(el => el.id),
          teacherId: userInfo.teacherId
        }
        that.loading = true
        batchDeleteHomeworkBag(params).then(response => {
          that.loading = false
          if (response.state === '11') {
            this.findBagListByCreator()
          }
        })
      }).catch((err) => {
        throw err
      })
    },
    statusChange() {
      this.page = 1
      this.findBagListByCreator()
    },
    // 获取作业包列表
    findBagListByCreator() {
      this.selection = []
      const { page, pageSize, startTime, endTime} = this
      if (!startTime) {
        this.$message.error('开始时间不能为空')
        return
      }
      const dataParam = {
        teacherId: this.userInfo.teacherId,
        schoolId: this.schoolId,
        page,
        pageSize,
        startTime,
        endTime
      }
      const {status} = this
      if (status !== '') dataParam.status = status
      this.loading = true
      this.list = []
      findBagPageByCreator(dataParam).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {data} = response
          const {pageContents} = data
          const {total} = data
          this.total = total
          for (let i = 0, len = pageContents.length; i < len; i += 1) {
            let {dateStart} = pageContents[i]
            const arr = dateStart.split('T')
            pageContents[i].dateStart = arr[0]
          }
          this.list = pageContents
          this.$store.commit('includeAdd', 'userWork')
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 设置作业包
    setWork(item) {
      this.$refs.EditWorkSet.dialogVisibleChange(item)
    },
    selectionChange(selection) {
      this.selection = selection
    },
    // 更新数据
    refreshPage() {
      this.findBagListByCreator()
    }
  }
}
</script>

<style lang="scss">
.checkByTeacher {
  .search-input {
    margin-right: 14px;
    input {
      background-color: #f6f6f6;
      height: 36px;
      padding-left: 36px;
      border: none;
    }
  }
}
</style>
<style lang="scss" scoped>
.header {
  @include flex(space-between, flex-start);
  flex-direction: column;
  background-color: #fff;
  height: 126px;
  padding: 0 28px 10px;
  margin-bottom: 10px;

  h1 {
    height: 84px;
    line-height: 84px;
    font-size: 20px;
  }

  .row {
    @include flex(space-between);
    width: 100%;
    font-size: 15px;

    .left {
      @include flex;

      .search-input {
        position: relative;

        .icon {
          background: url("../../assets/search.png") no-repeat center center;
          background-size: 16px 16px;
          width: 36px;
          height: 36px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .search-btn {
        background: linear-gradient(to right, #6DBBFC, #309AF2);
        width: 100px;
        margin-left: 14px;
        color: #fff;
        font-size: 15px;
        border-color: transparent;
      }
      .select-status{
        width: 110px;
        height: 32px;
        margin-left: 14px;
        ::v-deep .el-input{
          .el-input__inner{
            color: #333333;
            font-size: 14px;
          }
          .el-input__suffix{
            .el-input__suffix-inner{
              .el-select__caret{
                &::before{
                  color: #333333;
                  font-weight: 900;
                }
              }
            }
          }
        }
      }
    }
    .right{
      @include flex;
     .right-btn{
       height: 36px;
       font-size: 15px;
       line-height: 36px;
       border: 1px solid #309AF2;
       color: #309AF2;
       border-radius: 8px;
       background: #FFFFFF;
       padding: 0 14px;
       cursor: pointer;
       &.export{
         border-color: #309AF2;
         color: #309AF2;
       }
       &.delete{
         border-color: #FF3C30;
         color: #FF3C30;
       }
       &+.right-btn{
         margin-left: 14px;
       }
     }
    }

    .tips {
      color: #999999;
    }
  }
}

.line {
  width: 100%;
  height: 10px;
  background-color: #F6F6F6;
}

.column {
  @include flex(flex-start);
  background-color: #fff;
  height: 52px;
  padding-left: 28px;
}

.content {
  background-color: #fff;
  //height: calc(100vh - 84px);
  //overflow: auto;
  .pagination-container{
    padding: 8px 60px;
    text-align: right;
  }
}
</style>
